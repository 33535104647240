.script-multiple {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;

  .right-top {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .multiple-picture {
    display: flex;

    .left {
      flex: 1;

      @media screen and (min-width: 500px) {
        .picture-list {
          grid-template-columns: repeat(7, minmax(0, 1fr));
        }
      }

      @media screen and (min-width: 1860px) {
        .picture-list {
          grid-template-columns: repeat(10, minmax(0, 1fr));
        }
      }

      @media screen and (min-width: 2560px) {
        .picture-list {
          grid-template-columns: repeat(14, minmax(0, 1fr));
        }
      }

      .picture-list {
        display: grid;
        flex-wrap: wrap;
        gap: 4px;
        border: 4px solid rgb(47, 48, 44);
        // grid-template-columns: repeat(7, minmax(0, 1fr));
        padding: 6px 10px;

        .img {
          position: relative;
          cursor: pointer;
          margin-bottom: 0;
          padding-bottom: 0;
          width: 100%;
          padding-bottom: 100%;

          &.actived {
            img {
              border: 2px solid rgb(0, 255, 240);
            }
          }

          .icon-check {
            position: absolute;
            top: -1px;
            left: -1px;
            border-bottom-right-radius: 8px;
            color: black;
            box-sizing: border-box;
            padding: 2px;
            width: 20px;
            height: 17px;
            background-color: rgb(0, 255, 240);
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid transparent;
            box-sizing: border-box;
            object-fit: contain;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 320px;
      min-width: 320px;
      max-width: 320px;
      margin-left: 12px;

      .picture-preview-list {
        flex: 1;
        height: 0;
        display: flex;
        gap: 10px 6px;
        border: 4px solid rgb(47, 48, 44);
        padding: 6px 10px;
        max-height: 570px;
        overflow-y: scroll;
        overflow-x: hidden;
        flex-wrap: wrap;

        .img {
          position: relative;
          cursor: pointer;

          &.actived {
            img {
              border: 2px solid rgb(0, 255, 240);
            }
          }

          .name {
            margin: 6px 0;
            color: #fff;
            width: 135px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }

          .icon-check {
            position: absolute;
            top: -1px;
            left: -1px;
            border-bottom-right-radius: 8px;
            color: black;
            box-sizing: border-box;
            padding: 2px;
            width: 20px;
            height: 17px;
            background-color: rgb(0, 255, 240);
          }
          img {
            border: 2px solid transparent;
            display: block;
            width: 135px;
            height: 240px;
          }
        }
      }
    }
  }

  .multiple-videos {
    margin-top: 30px;

    .section-title {
      display: flex;
      align-items: center;

      .upload {
        margin-left: auto;
      }
    }

    .multiple-videos-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .list-item {
        position: relative;
        cursor: pointer;

        &.actived {
          img {
            border: 2px solid rgb(0, 255, 240);
          }
        }

        .name {
          margin: 6px 0;
          color: #fff;
          width: 135px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .ant-input {
          display: block;
          max-width: 138px;
          background-color: transparent;
          outline: none;
          box-shadow: none;
          border: none;
          color: white;
          height: 34px;
        }

        .icon-check {
          position: absolute;
          top: -1px;
          left: -1px;
          border-bottom-right-radius: 8px;
          color: black;
          box-sizing: border-box;
          padding: 2px;
          width: 20px;
          height: 17px;
          background-color: rgb(0, 255, 240);
        }

        img {
          display: block;
          width: 135px;
          height: 240px;
          background-color: rgb(40, 40, 44);
          object-fit: contain;
          border: 2px solid rgb(40, 40, 44);
        }

        .delete {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          padding: 4px;
          background-color: rgba(24, 24, 26, 0.9);
          top: 6px;
          right: 6px;
        }

        .play {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          padding: 4px;
          background-color: rgba(24, 24, 26, 0.9);
          top: 44px;
          right: 6px;
        }
      }
    }
  }

  .intro-videos {
    display: flex;
    flex-direction: column;

    .intro-videos-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      flex: 1;

      .list-item {
        position: relative;
        cursor: pointer;

        .name {
          margin: 6px 0;
          color: #fff;
          width: 135px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .ant-input {
          display: block;
          max-width: 138px;
          background-color: transparent;
          outline: none;
          box-shadow: none;
          border: none;
          color: white;
          height: 34px;
        }

        .icon-check {
          position: absolute;
          top: -1px;
          left: -1px;
          border-bottom-right-radius: 8px;
          color: black;
          box-sizing: border-box;
          padding: 2px;
          width: 20px;
          height: 17px;
          background-color: rgb(0, 255, 240);
        }

        &.actived {
          img {
            border: 2px solid rgb(0, 255, 240);
          }
        }

        img {
          display: block;
          width: 135px;
          height: 240px;
          border: 2px solid transparent;
        }

        .delete {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          padding: 4px;
          background-color: rgba(24, 24, 26, 0.9);
          top: 6px;
          right: 6px;
        }

        .play {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          padding: 4px;
          background-color: rgba(24, 24, 26, 0.9);
          top: 44px;
          right: 6px;
        }
      }
    }
  }
}

.avatar-upload {
  .ant-upload-list-item-actions {
    .anticon-eye {
      display: none;
    }

    .ant-tooltip {
      display: none;
    }
  }

  .ant-upload-list-item-container,
  .ant-upload.ant-upload-select {
    width: 120px !important;
    height: 120px !important;
  }
}

.upload-anchor-tip {
  background-color: aliceblue;
  color: #777;
  border-radius: 5px;
  margin-top: 10px;

  h5 {
    margin: 0px;
    padding: 10px 0px 0px 10px;
    font-weight: bolder;
  }
  ul {
    font-size: 0.8em;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  li {
    flex: 0 0 33%;
    list-style: none;
    line-height: 20px;

    b {
      font-weight: normal;
      color: #333;
    }
  }
}

.live-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-control {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 16px;

    .left {
      flex: 1;
    }

    .center {
      width: 400px;
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 16px;
    }

    .right {
      width: 320px;
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 16px;
    }

    .box-room {
      flex: 1;
      height: 0;
    }

    .box-people-control {
      height: 0;
      flex: 1;
    }

    .box-live {
      height: 380px;
      min-height: 380px;
    }
  }

  .live-box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    background-color: rgb(40, 40, 44);
    border-radius: 6px;
    box-sizing: border-box;

    &.box-segments {
      padding-right: 0;
    }

    .box-mask {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(109, 109, 109, 0.65);
      z-index: 2;
      color: #d4ff00;
      border-radius: 6px;
      font-weight: bold;

      .anticon-audio {
        font-size: 40px;
      }

      img {
        width: 60px;
      }
    }
  }

  .live-video {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    video {
      margin: 0 auto;
      width: 180px;
      height: 320px;
    }
  }

  .section-title {
    margin-bottom: 10px;
  }

  .live-video-url {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .room-id-input {
    height: 40px;
    border-radius: 4px !important;
  }

  .input-icon-end {
    display: flex;
    align-items: center;
  }

  .live-box {
    .live-box-title {
      display: flex;
      align-items: center;

      a {
        cursor: pointer;
      }
    }

    .live-box-main {
      display: flex;
      flex: 1;
      height: 0;

      &__list {
        flex: 1;
        height: 100%;
        overflow-y: scroll;
        padding-right: 16px;
        border-radius: 4px;

        .list {
          margin-bottom: 20px;
          padding: 12px 20px 8px 20px;
          background-color: #36363b;
          color: #fff;
          box-sizing: border-box;
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .list-sub {
          position: relative;
          background-color: #424245;
          padding: 12px 16px 1px 16px;
          margin-bottom: 10px;

          &:hover {
            .ot .play {
              opacity: 1;
            }
          }

          &.active {
            border: 1px dashed #d4ff00;
          }

          .title-sub {
            display: flex;
            font-size: 15px;
            font-weight: bold;

            .text {
              flex: 1;
              width: 0;
            }

            .ceil {
              margin-left: 12px;
              color: #2ae1d5;
              font-size: 13px;
              font-weight: normal;
            }
          }

          .ot {
            display: flex;
            align-items: center;
            top: 10px;
            right: 20px;

            .play {
              opacity: 0;
            }

            .living {
              display: flex;
              align-items: center;
              margin-left: 12px;
              color: #d4ff00;
              font-size: 14px;
            }

            .ant-btn {
              margin-left: 12px;
              height: 22px;
              font-size: 12px !important;
              padding: 0 8px !important;
              font-weight: normal;
            }
          }
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          margin: 10px 0;
          font-size: 13px;
          text-align: justify;
        }
      }
    }
  }
}

.loading-wave {
  width: 56px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 2px;
  height: 10px;
  margin: 0 4px;
  background-color: #d4ff00;
  border-radius: 2px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 1px;
  }

  50% {
    height: 10px;
  }

  100% {
    height: 1px;
  }
}

.anticon-menu-fold,
.anticon-menu-unfold {
  margin: 8px 0;
  color: #d4ff00;
  font-size: 18px;
  margin-right: 10px;
}

.list-menu {
  width: 140px;
  max-width: 140px;
  flex: 1;
  height: 100%;
  transition: width 0.3s ease 0s, max-width 0.3s ease 0s;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &.s-card {
    width: 164px;
    max-width: 164px;

    .ant-tree {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  &.collapsed {
    opacity: 0;
    width: 0;
    max-width: 0;
  }

  .ant-menu {
    height: 100%;
    background-color: rgb(40, 40, 44);
    color: #fff;

    .ant-menu-submenu-title,
    .ant-menu-item {
      margin: 0;
      padding-left: 0 !important;

      &:hover {
        color: #fff !important;
      }

      &:active {
        background: transparent;
      }
    }

    .ant-menu-submenu .ant-menu-item {
      padding-left: 24px !important;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected .ant-menu-submenu-title {
      background: transparent;
      color: #d4ff00 !important;

      &:hover {
        color: #d4ff00 !important;
      }
    }

    ul li:hover {
      color: #fff !important;
    }
  }
}

.ant-tree {
  background-color: #18181a;
  color: #fff;

  .ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree-indent-unit {
    width: 24px;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-tree-treenode {
    padding: 8px 0 8px 0;
  }

  .ant-tree-title {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 110px;
  }

  .ant-tree-node-selected {
    padding: 2px 6px;
    background-color: #333 !important;
    border-radius: 4px;
  }

  .ant-tree-draggable-icon {
    text-align: left !important;
    opacity: 0.7 !important;
    width: 16px !important;
    height: 16px !important;
  }

  path {
    fill: #fff;
  }
}

.base-modal {
  &-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }

  &-title {
    color: #fff;
    margin-top: 18px;
    font-weight: bolder;
  }

  &-desc {
    padding: 0 60px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-top: 12px;
    color: #b4b4b4;
    text-align: center;

    .detail {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        display: block;
        margin: 26px 0 16px 0;
        width: 250px;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 0 32px 10px 32px;
  }
}

.ant-btn {
  color: white !important;
  background-color: #28282c !important;
  border: solid 1px white !important;
  border-radius: 4px !important;

  &.btn-text {
    padding: 0 6px;
    color: #6b83ff !important;
    background: none !important;
    border: none !important;
    cursor: pointer !important;

    &:disabled {
      opacity: 0.7 !important;
      color: #6d6d6d !important;
      cursor: not-allowed !important;
    }
  }
}

.ant-popover {
  max-width: 600px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    background: #18181a;
    box-shadow: 0 0 10px #f0ecec;

    .ant-popover-inner-content {
      color: #fff;
    }
  }
}

.icon-help-circle {
  width: 24px;
  cursor: pointer;
}

.voice-upload,
.common-switch {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #6b83ff;

  .icon-video-switch {
    display: inline-block;
    width: 52px;
    height: 32px;
    cursor: pointer;
  }

  .divider {
    display: inline-block;
    margin: 0 4px;
    width: 0;
    height: 8px;
    border-right: 1px solid #434343;
  }
}

.upload-modal {
  .ant-modal-body {
    padding: 12px;

    .ant-upload-drag {
      padding: 12px;
      color: #fff;
    }
  }

  &-tips {
    padding: 8px 50px 26px;
    text-align: center;

    h4,
    p {
      padding: 0;
      margin: 0;
    }

    .text {
      margin-top: 4px;
      font-size: 13px;
      color: #b4b4b4;
    }
  }
}

.recording-modal {
  &-tips {
    padding: 8px 50px 26px;
    text-align: center;

    h4,
    p {
      padding: 0;
      margin: 0;
    }

    .text {
      margin-top: 4px;
      font-size: 13px;
      color: #b4b4b4;
    }
  }

  &-desc {
    overflow-y: auto;
    padding: 0;
    margin-bottom: 26px;
    max-height: 100px;
    text-align: left;

    .text {
      margin-top: 4px;
      margin-bottom: 0;
      font-size: 13px;
      color: #b4b4b4;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    .img-status {
      margin-bottom: 6px;
      width: 96px;
      height: 96px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .record-switch {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 10px;
    right: 100px;
  }

  &-content-row {
    position: relative;
    padding: 24px;
    background: #1c1d1e;
    border: 1px solid #434343;
    border-radius: 2px;

    .row-header {
      display: flex;
      align-items: center;
      text-align: left;

      .name {
        flex: 1;
        font-weight: bold;
        color: #6d6d6d;
      }

      .btns {
        .ant-btn {
          margin-left: 12px;
        }
      }

      .divider {
        display: inline-block;
        margin: 0 4px;
        width: 0;
        height: 8px;
        border-right: 1px solid #434343;
      }
    }

    .row-recording {
      display: flex;
      align-items: center;

      .img {
        width: 64px;
        height: 64px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .desc {
        display: inline-block;
        margin-left: 12px;
      }

      .ant-btn {
        margin-left: auto;
      }
    }
  }

  .ant-upload-drag {
    color: #fff;
    border: none;
  }

  .upload-row-content {
    display: flex;
    align-items: center;

    .left {
      flex: 1;

      h4,
      p {
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    .right {
      .ant-btn {
        margin-left: 12px;
      }
    }
  }
}

.random-line-modal {
  .ant-modal-body {
    max-height: 450px;
    overflow-y: scroll;
  }
}

.rich-text-content {
  p {
    position: relative;
    padding: 14px 50px 14px 12px;
    margin: 0;
    pointer-events: none;

    .sensitive {
      pointer-events: auto;
    }
  }

  .line-random {
    -webkit-user-modify: read-only;
    -moz-user-modify: read-only;
    pointer-events: auto;
    margin-top: 10px;

    .ant-btn {
      border: 1px solid #d4ff00 !important;
      color: #d4ff00 !important;
      background: rgb(40, 40, 44) !important;
      cursor: pointer;
    }

    .ant-btn-dangerous {
      border: 1px solid #fa4b3e !important;
      color: #fa4b3e !important;
      background: rgb(40, 40, 44) !important;
      cursor: pointer;
    }
  }

  &.voice-on {
    p {
      &::after {
        display: none;
        position: absolute;
        top: 14px;
        right: 10px;
        width: 24px;
        height: 24px;
        content: '';
        cursor: pointer;
        pointer-events: auto;
      }

      &.assistant {
        &::before {
          scale: 0.85;
          position: absolute;
          top: 16px;
          right: -12px;
          width: 20px;
          height: 20px;
          content: '\52a9';
          cursor: not-allowed;
          border-radius: 10px;
          border: 1px solid #d4ff00;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #d4ff00;
        }
      }

      &.voice-gray {
        &::after {
          display: inline-block;
          background: url('../../../res/icon-voice.png') no-repeat;
          background-size: 100%;
        }
      }

      &.voice {
        &::after {
          display: inline-block;
          background: url('../../../res/icon-voice-on.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}

.rich-random-line {
  p {
    position: relative;
    margin: 0;
    pointer-events: none;
    padding: 6px 48px 6px 10px;

    .sensitive {
      pointer-events: auto;
    }

    &::before {
      position: absolute;
      left: -10px;
      top: 14px;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: #d4ff00;
    }
  }

  &.voice-on {
    p {
      &::after {
        display: none;
        position: absolute;
        top: 6px;
        right: 10px;
        width: 24px;
        height: 24px;
        content: '';
        cursor: pointer;
        pointer-events: auto;
      }

      &.voice-gray {
        &::after {
          display: inline-block;
          background: url('../../../res/icon-voice.png') no-repeat;
          background-size: 100%;
        }
      }

      &.voice {
        &::after {
          display: inline-block;
          background: url('../../../res/icon-voice-on.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}


.customer-dropdown{

  .ant-dropdown-menu.ant-dropdown-menu-root{
    padding:15px;
    background-color: white;
    margin-bottom:15px;
  }
}

.customer-service {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width:56px;
  height:56px;
  border: 2px solid #434343;
  border-radius: 50%;
  text-align: center;
  color:white;
  font-size:1.5em;
  line-height: 56px;
  cursor: pointer;

  .qr-service {
    position: absolute;
    bottom: 180px;
    right: 0px;
    width: 192px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #1c1d1e;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  /* overflow: hidden; */
  overscroll-behavior: none;
  min-width: 1400px;
}

.App-link {
  color: #61dafb;
}

.ql-toolbar {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-modal-close {
  top: 12px !important;
  right: 12px !important;
  color: white !important;
}

.ant-modal-title {
  color: white !important;
  font-size: 1em !important;
}

.ant-modal-header {
  background-color: #2f302c !important;
  height: 40px !important;
  padding-left: 12px !important;
  padding-top: 12px !important;
}

.ant-modal-content {
  background-color: #18181a !important;
  color: white !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.time-remain-modal .ant-modal-content {
  border: 2px solid #ef446d;
  border-radius: 2px;
}
.time-remain-modal .ant-modal-close {
  top: -16px !important;
  right: -16px !important;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #28282c;
}

.time-remain-modal .ant-modal-close:hover {
  background: #28282c;
}

.ant-btn {
  border-radius: 4px !important;
  color: white !important;
  border: solid 1px white !important;
  background-color: #28282c !important;
}

.ant-btn.ant-btn-dangerous {
  background-color: #bd3a59 !important;
  border: none !important;
}

.ant-btn.ant-btn-dangerous:hover {
  box-shadow: 0 0 8px #bd3a59;
  border: none !important;
}

.ant-btn.ant-btn-primary {
  background-color: #293be3 !important;
  border: none !important;
}

.ant-btn.ant-btn-primary:hover {
  box-shadow: 0 0 8px #293be3;
}

.ant-btn:disabled {
  opacity: 0.5 !important;
  box-shadow: none !important;
}

.ant-btn.highlight-contentful {
  background-color: #d4ff00 !important;
  color: black !important;
  border: none !important;
  font-size: 14px !important;
  padding: 0 34px !important;
  height: 42px;
  font-weight: bold;
}

.ant-btn.highlight-contentful.pd0 {
  padding: 0 !important;
}

.ant-btn.highlight-contentful:hover {
  box-shadow: 0 0 8px #d4ff00;
}

.ant-btn.highlight-contentful:disabled {
  background-color: #1c1d1e !important;
  color: #6d6d6d !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2f302c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6d6d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(212, 255, 0, 0.5);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1c1d1e inset !important;
}

.dark tr {
  background-color: #1c1d1e;
}

.dark th,
.dark td {
  background-color: transparent !important;
  border-color: #434343 !important;
}

.dark thead th,
.dark thead td {
  background-color: #28282c !important;
}

$primary-color: #D4FF00

.disabled
  .moveable-line
    height: 0 !important
    width: 0 !important

.moveable-line
  background-color: rgba(0, 255, 240, 1) !important

.moveable-control
  border: none !important
  border-radius: 0 !important
  background-color: rgba(0, 255, 240, 1) !important

.moveable-button
  width: 24px
  height: 24px
  margin-bottom: 4px
  background: rgba(0, 0, 0, 0.60)
  border-radius: 8px
  appearance: none
  border: solid 2px #61dafb
  color: white
  font-weight: bold


.panel
  padding-left: 24px
  height: 52px
  box-sizing: border-box
  color: white
  display: flex
  align-items: center
  justify-content: flex-start

  path
    fill: white

  .icon
    margin-right: 8px

.highlighted
  color: #61dafb

.sensitive
  color: red

#sensitive-pop
  position: absolute
  left: 0
  top: 0
  border: 1px solid red
  padding: 5px 15px
  border-radius: 5px
  color: red
  display: none

.panel:hover
  background-color: rgba(48, 55, 12, 0.22)
  color: #D4FF00

  path
    fill: #D4FF00

.panel.selected
  color: #D4FF00
  background-color: #30370C

  path
    fill: #D4FF00

.section-title
  color: white
  font-weight: bold
  margin-bottom: 20px
  font-size: 16px

.sub-section-title
  color: white
  margin-bottom: 16px
  margin-top: 20px
  font-size: 1.1em

.lexical-placeholder
  color: #aaa

.weak-info
  color: #B4B4B4

.horizontal-separator
  background-color: rgba(67, 67, 67, 1)
  height: 1px
  width: 100%

.small-square-button
  height: 16px
  width: 16px
  transform: translate(0, 1px)
  border-radius: 4px
  box-sizing: border-box
  padding: 4px
  background-color: #293BE3

.input-with-hint
  border: solid 1px rgba(67, 67, 67, 1) !important
  border-radius: 8px !important
  height: 44px
  background-color: transparent !important
  margin-top: 8px !important
  padding-left: 14px !important
  padding-top: 6px !important
  padding-bottom: 4px !important
  color: white !important
  z-index: unset !important

  &.input
    padding-top: 2px !important

  &.small
    width: 80px
    height: 26px
    margin: 0 10px
    padding-top: 2px !important
    padding-left: 12px !important
    margin-top: 0 !important
    border-radius: 4px !important

    .ant-input
      color: #D4FF00 !important

    .ant-input-disabled
      color: #6D6D6D !important

    .ant-input-suffix
      padding-top: 2px

  .ant-input
    background-color: transparent !important
    color: white !important
    padding-top: 6px !important
    padding-bottom: 4px !important

  .ant-input::placeholder
    color: #6D6D6D !important

textarea.input-with-hint
  padding-top: 12px !important

.script-select
  padding-left: 3px !important

.borderless
  border: none !important

  *
    border: none !important

.shadowless
  box-shadow: none !important

  *
    box-shadow: none !important

.fantasy-select
  &.ant-select-disabled
    .ant-select-selector, .ant-select-arrow
      color: #6D6D6D !important

      *
        color: #6D6D6D !important

  .ant-select-arrow
    color: white !important

  .ant-select-selector
    color: white !important
    background-color: transparent !important

    *
      color: white !important

  .ant-select-selector
    box-shadow: none !important
    border: none !important

    *
      box-shadow: none !important
      border: none !important

.ant-dropdown-menu.ant-dropdown-menu-root
  background-color: #18181A

.fantasy-popup
  background-color: #18181A

  .ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected,
  .ant-select-item-option.ant-select-item-option-selected
    background-color: rgba(212, 255, 0, 1) !important
    color: black !important

  .ant-select-item-option.ant-select-item-option-active
    background-color: rgba(212, 255, 0, 0.3) !important

  .ant-select-item-option
    color: white !important

.input-with-hint.ant-input-affix-wrapper-status-error
  border-color: #EF446D !important

.input-with-hint::placeholder
  color: #6D6D6D !important

.input-hint
  background-color: #1C1D1E
  font-size: 14px
  position: absolute
  left: 10px
  top: 0
  color: white
  padding-left: 6px
  padding-right: 6px

#mobile-login-button
  .ant-btn-loading-icon
    display: none

.moveable-button.disabled
  opacity: 0.5

.moveable-button.enable_alpha
  background-color: green

.ql-toolbar
  display: none !important

.link-button
  background-color: transparent
  border: none
  color: #6B83FF
  cursor: pointer

#room-table, .dark-table
  background: transparent !important

  .ant-table-tbody
    background-color: #1A1A1C

  *
    border-color: rgba(67, 67, 67, 1) !important
    border-radius: 0 !important
    background: transparent

  .ant-table-thead
    background-color: #28282C !important

    .ant-table-cell-scrollbar
      box-shadow: none !important

  .ant-table-cell
    color: white !important
    background-color: transparent !important

.ant-tabs-tab
  padding: 0 !important

.ant-tabs-ink-bar
  background: rgba(212, 255, 0, 1) !important

.ant-tabs-tab
  justify-content: center !important
  border-bottom: solid 2px rgba(67, 67, 67, 1) !important

.ant-tabs-tab:hover
  justify-content: center !important
  border-bottom: solid 2px rgba(212, 255, 0, 0.2) !important

.ant-tabs-tab-btn
  margin-left: 0 !important
  color: white !important
  margin-top: 12px !important
  margin-bottom: 12px !important

.ant-tabs-tab:hover
  .ant-tabs-tab-btn
    color: rgba(212, 255, 0, 1) !important

.ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
    color: rgba(212, 255, 0, 1) !important

.ant-tabs-top
  .ant-tabs-nav-list
    width: 100% !important
    display: flex !important
    flex-direction: row !important

.ant-tabs-tab
  flex: 1 !important
  width: 0 !important

.ant-tabs-content
  height: 100% !important

.ant-tabs-tabpane
  height: 100% !important

#script-list
  .ant-tabs-nav
    width: 100% !important

    .ant-tabs-tab
      width: 100% !important
      border: none !important
      justify-content: left !important
      padding-left: 16px !important

  .ant-tabs-content-holder
    width: 0 !important
    visibility: collapse !important

  .ant-tabs-ink-bar
    left: 0 !important
    right: unset !important

.ant-empty
  height: 100px !important

  *
    display: none !important

.ant-notification-notice
  background-color: rgb(40, 40, 44) !important

  *
    color: white !important

  .anticon-close-circle
    path
      color: #EF446D !important

.border-button
  display: flex

  &.selected

    border-color: $primary-color !important
    color: $primary-color !important

    svg
      path
        stroke: $primary-color

  svg
    margin-right: 8px

    path
      stroke: white

svg.weak
  path
    stroke: #6D6D6D

svg.strong
  path
    stroke: $primary-color

.drag-handle,.collapse-handle
  opacity: 0.5

  &:hover
    opacity: 1

.linked-line span
  text-decoration: underline $primary-color

.resource-popover
  .ant-popover-inner
    padding: 0

.ant-spin-container, .ant-spin-nested-loading
  height: 100%

.ant-spin-nested-loading .ant-spin-container::after
  background: #00000022 !important

*
  > .hidden-button
    visibility: hidden

  &:hover
    > .hidden-button
      visibility: visible
      opacity: 0.5

      &:hover
        opacity: 1

.hidden-img
  cursor: pointer
  opacity: 0.5
    
  &:hover
    visibility: visible
    opacity: 1
    
::-moz-selection
  background: adjust-color($primary-color, $alpha: -0.6)
  color: #cfd5b1

::selection
  background: adjust-color($primary-color, $alpha: -0.6)
  color: #cfd5b1

.ant-tabs-nav-operations
  display: none !important

.license
  color: rgba(180, 180, 180, 1)
  text-decoration: none

.ctrl-his-line
  margin: 10px 12px 10px 0
  font-size: 14px
  clear: both

  &.me
    float: right
    color: #D4FF00
  &.help
    color: #eee
      
  span
    color: rgb(107, 131, 200)

.ctrl-his-line-content
  font-size: 14px
  color: rgb(107, 131, 200)


.mk-help-line
  background:#777
  border-radius: 5px
  padding:10px
  width:90%

  li
    list-style:number

.room-his-line
  margin: 6px 12px
  font-size: 0.9em

  span
    color: rgb(107, 131, 200)

    &.comment
      color: white

  b
    padding: 0 2px
    font-weight: normal
    color: rgb(212, 255, 0)

.room-type-select
  &.ant-select
    .ant-select-selection-item
      color: $primary-color !important

.layer-dropdown
  z-index: 3005

  .ant-popover-title
    color: white

  .ant-popover-inner-content
    img
      max-width: 80px
      max-height: 40px
      margin: 5px
      cursor: pointer
      border: 1px solid transparent

      &:hover,&.selected
        border: 1px solid white

.layer-tools
  position: absolute
  top: 8px
  width: 360px

  button.ant-btn
    float: right
    margin-right: 5px
    border: none

.ant-switch
  background: #757575

  &:hover
    background: #9b9898 !important

  &.ant-switch-checked
    background: #3DE959 !important

.rCS1h1rqv3
  z-index: 999 !important


.ant-progress
  .ant-progress-inner
    background-color: #d0d0d0

    .ant-progress-bg
      height: 16px !important

  .ant-progress-text 
    color: #fff

.play-green
  path
    fill: #3DE959

.placeholder-gray::placeholder
    color: #6d6d6d !important


.ant-input-number
  .ant-input-number-input
    color: #fff  

.play-circle
  &.selected
    path
      fill: #D4FF00

.ellipsis
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.segment-card
  &:last-of-type
    margin-bottom: 0 !important
  .script-card
    &:last-of-type
      margin-bottom: 0

.ant-popconfirm
  .ant-popconfirm-inner-content
    .ant-popconfirm-message
      color: #faad14 !important
    .ant-popconfirm-description
      color: #fff !important

.video-list

  @media screen and (min-width: 500px)
    .ant-row
      grid-template-columns: repeat(7, minmax(0, 1fr))

  @media screen and (min-width: 1860px)
    .ant-row
      grid-template-columns: repeat(10, minmax(0, 1fr))
  
  @media screen and (min-width: 2560px)
    .ant-row
      grid-template-columns: repeat(14, minmax(0, 1fr))

  .ant-row
    display: grid


      

    .ant-list-item
      display: flex

  .ant-spin-nested-loading
    height: auto

.module-mask
  position: absolute
  top: 0
  left: 0
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  width: 100%
  height: 100%
  background-color: rgba(109, 109, 109, 0.65)
  cursor: not-allowed
  color: #d4ff00
  font-weight: bold
  font-size: 20px
   
   
